body{
  background-color: #0e0e0e;
  width: 100vw;
  height: 100vh;
  display: block;
  overflow: hidden;
}
.logo-wrapper{
  width: 10vw;
  height: auto;
  margin: 5vh;
  position: absolute;
}
.logo-wrapper a{
  width: 10vw;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#settings-form{
  display: block;
  width: 100%;
  height: auto;
  min-height: 300px;
}

.area-to-grab{
    width: 100%;
    display: block;
    float: left;
    height: 30px;
    background-color: white
}

.area-to-grab:hover{
  cursor: move;
}

.add-layer-btn-wrapper{
  display: block;
  float: left;
  height: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#canvasWrapper{
  display: block;
  overflow: hidden;
  width: 50vw;
  height: 100vh;
  position: relative;
  margin: 0 25vw 0 25vw;
}
#app-config{
  width: 16vw;
  display: block;
  position: fixed;
  background-color: #0e0e0e;
  height: auto;
  min-height: 300px;
  z-index: 100;
  right: 0;
  top: 10vh;
  box-shadow: 1px 3px 8px 0px #000000;
}

#app-config .dragging{
  box-shadow: 2px 4px 9px 0px #202020;
}
form fieldset{
  border: none;
  display: block;
  float: left;
  width: 100%;
  padding: 10px 0 10px 10px;
}
form label {
  color: white;
  width: auto;
  min-width: 30%;
  text-align: left;
  display: block;
  float: left;
}

h2{
  font-size: 1em;
  color: white;
}
canvas{
  width: 50vw;
  height: 100vh;
  display: block;
  margin: 0;
  position: fixed;

}

.cover-header{
  position: absolute;
  z-index: 2;
  width: calc(100vw - 40px);
  min-height: 100px;
  background-color: black;
  top: 0;
  text-align: left;
  transition: 100ms;
  transform-origin: center;
}
.cover-header h1{
  color: white;
}


[contenteditable] {
  outline: 0px solid transparent;
}
.toggles-wrapper{
  display: block;
  float: left;
  height: 75vh;
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
}
.toggle{
  display: block;
  float: left;
  position: relative;
  width: 95%;
  height: auto;
  overflow: hidden;
  border-bottom: 1px solid black;
}

.toggle .toggle-title-wrapper{
  display: flex;
  height: auto;
  min-height: 50px;
  color: white;
}

.toggle .toggle-title-wrapper .toggle-title{
  font-size: 18px;
  height: auto;
  color: white;
  margin: auto 5px auto 5px;
  display: block;
}
.toggle .toggle-title-wrapper .picto-toggle{
  display: block;
  width: 20px;
  height: 20px;
  margin: auto 5px auto 0px;
  background: red;
}

.toggle .toggle-title-wrapper .toggle-miniature{
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  margin: auto 0px auto auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


.toggle .toggle-title-wrapper .picto-toggle:hover{
  cursor: pointer;
  background-color: green;
  transition: 300ms;
}

.toggle .togglee-title:hover{
  cursor: pointer;
}

.toggle .togglee-title .picto-toggle{
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  background-color: blueviolet;
  margin: auto 20px auto 5px;
}

.toggle .toggle-content{
  display: block;
  position: relative;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 300ms;

}


.toggle .toggle-content .toggle-content-wrapper{
  display: block;
  float: left;
  width: 100%;
  height: auto;
  position: relative;
}

.toggle .toggle-content fieldset{
  border-bottom: 1px solid black;
}
.toggle .toggle-content fieldset label{
  width: 100%;
}

.toggle .toggle-content fieldset input{
  width: calc(100% - 10%);
  padding: 0 0% 0 1%;
}
.toggle .toggle-content fieldset select{
  width: calc(100% - 10%);
  padding: 0 5% 0 5%;
}

.inputs-wrapper{
  width: 100%;
  height: auto;
}
.image-imported{
  position: absolute;
}

.image-imported img{
  position: relative;
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}

.inputs-wrapper .input-explicit{
  width: 20%;
  height: auto;
  display: block;
  float: left;
}

.inputs-wrapper .input-implicit{
  width: 80%;
  height: auto;
  display: block;
  float: left;
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}




@font-face {
  font-family: "Camar";
  src: local("Camar") , url(./fonts/Camar.otf);
}

@font-face {
  font-family: "Cremona";
  src: local("Cremona") , url(./fonts/Cremona.ttf);
}

@font-face {
  font-family: "IMBPlexonaMono";
  src: local("IMBPlexonaMono") , url(./fonts/IBMPlexMono-Regular.ttf);
}

@font-face {
  font-family: "Luxia";
  src: local("Luxia") , url(./fonts/Luxia-Display.otf);
}

@font-face {
  font-family: "Pioggia";
  src: local("Pioggia") , url(./fonts/Pioggia.otf);
}
